import * as React from 'react'
import { BusHttpRequest } from "./../../components/utility";
import {Auth, API} from 'aws-amplify'
import AWSAmplify from 'aws-amplify'
import AWS from 'aws-sdk';
var quicksight = require("amazon-quicksight-embedding-sdk");
require('dotenv').config();


interface IDashboardProps {
    dashboardId: string
}

interface IDashboardState {

}

class DashboardPage extends React.Component<IDashboardProps,IDashboardState> {

    componentDidMount() {
  
        this.getEmbedUrl()
    }

    public   getToken() {
        return AWSAmplify.Auth.currentSession()
          .then(function (session: any) { return session; })
          .catch(function (err: any) { console.log(err);});
    }

    public dictFromQuery(query_params:string) {
 
        var res:any = {};
        var str = query_params.replace("?","").split("&");
        str.map(x => res[x.split("=")[0]] = x.split("=")[1]);

        return res;
    }

    public getEmbedUrl= async () => {
        console.log(this.props.dashboardId);
        const data = await Auth.currentSession();
        const jwtToken = data.getIdToken().getJwtToken();
        const payloadSub = data.getIdToken().payload.sub;
        const email = data.getIdToken().payload.email;

        var queryParams = this.dictFromQuery(window.location.search);

        // const dashboardId = window.location.href.split("?")[1].split("&")[0]
        
        const params = { 
          //  headers: {},
          //  response: true,
                jwtToken: jwtToken,
                payloadSub: payloadSub,
                email: email,
                dashboardId: queryParams.did
        }

        /*const qsEmbed = await API.get('quicksight', '/getEmbedUrlStarter', params);
        console.log(JSON.stringify(qsEmbed));
        const containerDiv = document.getElementById("embed");
  
        const options = {
            url: qsEmbed.EmbedUrl,
            container: containerDiv,
            parameters: {
                country: "United States"
            },
            scrolling: "no",
            height: "800px",
            width: "912px",
            footerPaddingEnabled: true,
        };
        const dashboard = quicksight.embedDashboard(options);*/
        
        const user = await Auth.currentAuthenticatedUser();
        const userInfo = await Auth.currentUserInfo();
        var res = null;
        if (user) {
          BusHttpRequest.updateAuthToken(user.signInUserSession.idToken.jwtToken);
          var body = JSON.stringify(params);
          BusHttpRequest.fetchEmbed<any>({method: "POST", body})
          .then((d) => {
              console.log(JSON.stringify(params));
              console.log("RESPONSE " + JSON.stringify(d));
              const containerDiv = document.getElementById("embed");
        
              const options = {
                  url: d.EmbedUrl,
                  container: containerDiv,
                  parameters: {
                      country: "United States"
                  },
                  scrolling: "no",
                  height: queryParams.h,
                  width: queryParams.w,
                  footerPaddingEnabled: true,
              };
              const dashboard = quicksight.embedDashboard(options);
          })
          .catch((error) => console.log(error));
      }   
        this.setState({ loader: false });
    }
        


    render() {
        return (
        
            <div id="embed">
            </div>

        )
    }

}

export {
    DashboardPage
}

