import * as React from "react";
import { AmplifySignOut } from "@aws-amplify/ui-react";
import "./index.css";
import { NavLink } from "react-router-dom";
import { faAlignLeft } from "@fortawesome/free-solid-svg-icons";
// var AWS = require('aws-sdk/dist/aws-sdk-react-native');
import AWS from "aws-sdk";
import { BusHttpRequest } from './../../components/utility';
import { CognitoUserPool, CognitoUserAttribute, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';

interface INavbarProps {
  isAdmin: boolean;
  user: any;
  system: string;
}

interface IMenu {
  elements:IMenuElement[]
}

interface IMenuElement {
  componentId: string,
  label: string;
  type: string;
  value: string;
}

interface IDashProps { 
  id : string,
  width : number,
  height : number
}

interface INavbarState {
  menu : IMenu
}

class Navbar extends React.Component<INavbarProps, INavbarState> {

  state: INavbarState = {
    menu: this.emptyMenu()
}

componentDidMount() {

  var x = this;
  // Amazon Cognito creates a session which includes the id, access, and refresh tokens of an authenticated user.

  var menu = {elements:[]}; // {elements:[ {label: "Stuff", type: "LINK", value: "/"},{label: "Analytics", type:"DASHBOARD",value:"{\"id\":\"f3c10a2b-b605-4690-a8cc-3d3feb2d3272\",\"width\":912,\"height\":800}"}]};

  var authenticationData = {
    Username : 'testUser',
    Password : 'BqvJ19TMnVZFk^CU',  
  };
  var authenticationDetails = new AuthenticationDetails(authenticationData);
  var poolData = { UserPoolId : 'us-east-1_dXZjGtMmY',
    ClientId : '7gr6fihih8qcnupm88p06mlsgm'
  };
  var userPool = new CognitoUserPool(poolData);
  var userData = {
    Username : 'testUser',
    Pool : userPool
  };
  var cognitoUser = new CognitoUser(userData);
  console.log("Calling API FOR BLACKLIGHT POOL...");
  cognitoUser.authenticateUser(authenticationDetails, {
    onSuccess: function (result:any) {
        var accessToken = result.getAccessToken().getJwtToken();
        // Use the idToken for Logins Map when Federating User Pools with identity pools or when passing through an Authorization Header to an API Gateway Authorizer 
        var idToken = result.idToken.jwtToken;
        const data = {
          request: {  },
          module: 'AppBuilder', action: 'getApp'
        }
        const method = 'POST' // or GET?
        const body = JSON.stringify(data)
        const endpoint = 'https://1x6uadximd.execute-api.us-east-1.amazonaws.com/dev/services'
    
        BusHttpRequest.updateBLAuth(idToken);
        BusHttpRequest.fetchApp<any>({ method: method, body: body })
          .then((v) => {
            var elements = v.components;
            x.setState({menu:{elements: elements}});
          });
        
    },
    onFailure: function(err:any) {
        console.log(JSON.stringify(err));
    },
  });

}

emptyMenu() {
  return {elements:[]};
}

  renderMenu() {

    if(this.state.menu) {
      return (
        <div className="breadcrumb mb-0 w-100 border">
    
        <ul className="navbar-nav ml-lg-auto">
          {/* As routes are added, <NavLink> can handle Active Route*/}
   
              {this.state.menu.elements.map(el => this.renderMenuElement(el)) }
        </ul>
      </div>
      )
    }
  }

  renderMenuElement(x:IMenuElement) {

    if(x.type==="LINK") {
      return(
        <li className="nav-item mr-3 align-self-lg-center">
          <a className="nav-link font-weight-bold" href={x.value}>{x.label}</a></li>
          );
    }
    if(x.type==="DASHBOARD") {
      var dashProps:IDashProps = JSON.parse(x.value);
      var dashLink = "/Dashboard?did=" + dashProps.id + "&h=" + dashProps.height + "&w=" + dashProps.width;
      console.log("DASHBOARD going to  " + dashLink);
      return(
        <li className="nav-item mr-3 align-self-lg-center">
          <a className="nav-link font-weight-bold" href={dashLink}>{x.label}</a></li>
          );
    }
  }

  render() {
    return (
      <nav className="navbar navbar-expand-lg navbar-light">
        <a className="navbar-brand d-lg-none" href="./index.html">
          <img src="/NCI-Logo.png" alt="logo" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto">
          <li>
          <img src="/NCI-Logo.png" alt="logo" height="100px"  />
       </li>
            <li className="nav-item active mr-3 align-self-lg-center">
              <span className="nav-link">
                Welcome, {this.props.user ? this.props.user.username : "user"}{" "}
                <span className="sr-only">(current)</span>
              </span>
            </li>
            <li className="nav-item active mr-3 align-self-lg-center">
              <span className="nav-link">
                {this.props.system} <span className="sr-only">(current)</span>
              </span>
            </li>
            {this.props.isAdmin && (
              <li className="nav-item active dropdown mr-3 align-self-lg-center">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Admin
                </a>
                <div
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <a className="dropdown-item" href="/admin">
                    Users
                  </a>
                </div>
              </li>
            )}
            <li className="nav-item">
              <AmplifySignOut buttonText="Logout"></AmplifySignOut>
            </li>
          </ul>

          <div className="w-100 d-flex mt-4">
            <a
              className="navbar-brand d-none d-lg-block align-self-center"
              href="./index.html"
            >
              
            </a>

            {  this.renderMenu() }


          </div>
        </div>
      </nav>
    );
  }
}

export { Navbar };
