import 'bootstrap/dist/css/bootstrap.min.css'
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import AWSAmplify from 'aws-amplify'

AWSAmplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: 'us-east-1',
    userPoolId: 'us-east-1_nZPy99jA5',
    identityPoolId: 'us-east-1:f437c6ed-3b85-4b39-a38b-d1439234663d',
    userPoolWebClientId: '7713okb79diltodv4dlcsgtjr3'
  },
  API: {

      endpoints: [
          {
              name: "quicksight",
              endpoint: "https://q2x23je2ef.execute-api.us-east-1.amazonaws.com/default"
          }
      ]
  }
})

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
