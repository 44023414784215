import React,{useEffect} from 'react'
import {Auth, API} from 'aws-amplify'
import AWSAmplify from 'aws-amplify'
import AWS from 'aws-sdk';
import { BusHttpRequest } from "./../../components/utility";
var quicksight = require("amazon-quicksight-embedding-sdk");
require('dotenv').config();

interface IEmbed {
    EmbedUrl: string,
    RequestId: string
}

class HomePage extends React.Component {

    componentDidMount() {
  
        this.getEmbedUrl()
    }

    public   getToken() {
        return AWSAmplify.Auth.currentSession()
          .then(function (session: any) { return session; })
          .catch(function (err: any) { console.log(err);});
    }
      

    public getEmbedUrl= async () => {
        const data = await Auth.currentSession();
        const jwtToken = data.getIdToken().getJwtToken();
        const payloadSub = data.getIdToken().payload.sub;
        const email = data.getIdToken().payload.email;
        
        const params = { 
          //  headers: {},
          //  response: true,
                jwtToken: jwtToken,
                payloadSub: payloadSub,
                email: email
            
        }

        /*const qsEmbed = await API.get('quicksight', '/getEmbedUrlStarter', params);
        console.log(JSON.stringify(qsEmbed));
        const containerDiv = document.getElementById("embed");
  
        const options = {
            url: qsEmbed.EmbedUrl,
            container: containerDiv,
            parameters: {
                country: "United States"
            },
            scrolling: "no",
            height: "800px",
            width: "912px",
            footerPaddingEnabled: true,
        };
        const dashboard = quicksight.embedDashboard(options);*/
        
        const user = await Auth.currentAuthenticatedUser();
        const userInfo = await Auth.currentUserInfo();
        var res = null;
        if (user) {
          BusHttpRequest.updateAuthToken(user.signInUserSession.idToken.jwtToken);
          var body = JSON.stringify(params);
          BusHttpRequest.fetchEmbed<any>({method: "POST", body})
          .then((d) => {
              console.log(JSON.stringify(params));
              const containerDiv = document.getElementById("embed");
        
              const options = {
                  url: d.EmbedUrl,
                  container: containerDiv,
                  parameters: {
                      country: "United States"
                  },
                  scrolling: "no",
                  height: "800px",
                  width: "912px",
                  footerPaddingEnabled: true,
              };
              const dashboard = quicksight.embedDashboard(options);

          })
          .catch((error) => console.log(error));
      }   
        this.setState({ loader: false });
    }
        
    public   embedDashboard(givenUrl : String) {
            // HomePage.getEmbedUrl(function(data: any) {
            var containerDiv = document.getElementById("embed");
            var options = {
                url: givenUrl,
                container: containerDiv,
                parameters: {
                    country: 'United States'
                },
                scrolling: "no",
                height: "700px",
                width: "1000px"
            };
            var dashboard = quicksight.embedDashboard(options);
    
            //  });
            // dashboard.on('error', onError);
            // dashboard.on('load', onDashboardLoad);
    }



    render() {
        return (<></>)

    }

   public stuff () {
    let awsCredentials = {
        region: "us-east-1",
        accessKeyId: "", // process.env.AWS_ACCESS_KEY,
        secretAccessKey: "" // process.env.AWS_SECRET_KEY
      };

      Auth.currentCredentials().then(res => {

        AWS.config.update({
            region: 'us-east-1',
            credentials: res
        });



     // console.log("AWS ACCESS:" + awsCredentials.accessKeyId);
   //  AWS.config.update(awsCredentials);

            //  console.log('Token '+ idToken);

             Auth.currentAuthenticatedUser().then(function (user) {
                var token = user.signInUserSession.idToken.jwtToken
            console.log("Called and using this token :" + token);
           // console.log(JSON.stringify(userToken));
            AWS.config.region = 'us-east-1';
           AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                IdentityPoolId: "us-east-1:f437c6ed-3b85-4b39-a38b-d1439234663d",
                Logins: {
                    "cognito-idp.us-east-1.amazonaws.com/us-east-1_P2q4y3PHu": token
                }
            });
            // console.log(AWS.config.credentials);
            var params = {
                //DurationSeconds: 3600,
                //ExternalId: "123ABC",
                RoleArn: "arn:aws:iam::092995245000:role/Cognito_bus_solutionsAuth_Role",
                RoleSessionName: "100006"
            };
            var sts = new AWS.STS({
                apiVersion: '2011-06-15'
            });
            sts.assumeRole(params, function (err : any, data : any) {
                if (err) console.log("Assumwe erri :::::::::::::::::: ", err, err.stack); // an error occurred
                else {
                    // console.log("data: "+data);
                    var params = {
                        AwsAccountId: '092995245000',
                        Email: 'DaveM@bussolutions.com', //used in creating userpool
                        IdentityType: 'QUICKSIGHT', // IAM | QUICKSIGHT, /* required */
                        Namespace: '100006',
                        UserRole: 'READER', //ADMIN | AUTHOR | READER | RESTRICTED_AUTHOR | RESTRICTED_READER, /* required */
                        IamArn: 'arn:aws:quicksight:us-east-1:092995245000:user/100006/100006',
                        SessionName: "100006",
                    };
                    AWS.config.update({
                        accessKeyId: data.Credentials.AccessKeyId,
                        secretAccessKey: data.Credentials.SecretAccessKey,
                        sessionToken: data.Credentials.SessionToken,
                        "region": "us-east-1"
                    });
                    quicksight.getDashboardEmbedUrl({
                        AwsAccountId: "092995245000",
                        DashboardId: "f3c10a2b-b605-4690-a8cc-3d3feb2d3272",
                        IdentityType: "IAM",
                        ResetDisabled: true,
                        SessionLifetimeInMinutes: 400,
                        UndoRedoDisabled: false
                    },
                    function (err : any, data : any) {
                        if (!err) {
                            console.log("Here is what we got for the URL !!!!!********!!!!!!");
                            console.log(data);
                           // this.embedDashboard(data);

                        } else {
                            console.log(err);
                        }
                    }
                );   
                
/*
                    quicksight.registerUser(params, function (err : any, data1 : any) {
                        if (err) {
                            console.log(":::::::::::::::::::::::");
                            console.log(JSON.stringify(err));
                            if (err.statusCode == 409) {
                                // console.log("Register User :::::::::::::::: ", data1);
                                quicksight.getDashboardEmbedUrl({
                                        AwsAccountId: "092995245000",
                                        DashboardId: "f3c10a2b-b605-4690-a8cc-3d3feb2d3272",
                                        IdentityType: "IAM",
                                        ResetDisabled: true,
                                        SessionLifetimeInMinutes: 400,
                                        UndoRedoDisabled: false
                                    },
                                    function (err : any, data : any) {
                                        if (!err) {
                                            console.log(Date());
                                            callback(data);
                                        } else {
                                            console.log(err);
                                            callback();
                                        }
                                    }
                                );   console.log("err register user ::::::::::::::::::", err, err.stack);
    } 
                          
                        } // an error occurred
                        else {
                            // console.log("Register User :::::::::::::::: ", data1);
                            quicksight.getDashboardEmbedUrl({
                                    AwsAccountId: "092995245000",
                                    DashboardId: "f3c10a2b-b605-4690-a8cc-3d3feb2d3272",
                                    IdentityType: "IAM",
                                    ResetDisabled: true,
                                    SessionLifetimeInMinutes: 400,
                                    UndoRedoDisabled: false
                                },
                                function (err: any, data: any) {
                                    if (!err) {
                                        console.log(data);
                                        callback(data);
                                    } else {
                                        console.log(err);
                                        callback();
                                    }
                                }
                            );
                        }
                    });*/
                }
            });
        });

        });
    }
}

export {
    HomePage
}

