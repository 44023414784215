import * as React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { AdminPage } from '../../../containers/AdminPage'
import {TenantsPage} from "../../../containers/TenantsPage";

interface IAdminRouteProps {
    isAdmin: boolean
}

export class AdminRoute extends React.Component<IAdminRouteProps, {}> {

    render() {
        return (
            <>
                {this.props.isAdmin
                    ? <Route exact path="/admin" component={AdminPage} />
                    : <Redirect to="/" />
                }
            </>
        )
    }
}