import * as React from 'react'
import { Modal, Button, Form, Alert } from 'react-bootstrap'
import { ITenant, BusHttpRequest, IUser } from '../../../components/utility'

interface IAddTenantModalProps {
    token: string
    tenants: ITenant[]
    onAddTenant?: (newTenant: ITenant) => void
}

interface IAddTenantModalState {
    errorMessage?: string
    temporaryPassword: string
    tenantName: string
    tenantAbbreviation: string
    tenantId: number
    openModal: boolean
    submittingForm: boolean
    isValidAbbreviation: boolean
    isValidTenantName: boolean
}

export class AddTenantModal extends React.Component<IAddTenantModalProps, IAddTenantModalState> {
    state: IAddTenantModalState = {
        temporaryPassword: '',
        tenantName: '',
        tenantAbbreviation: '',
        tenantId: 0,
        openModal: false,
        submittingForm: false,
        isValidAbbreviation: true,
        isValidTenantName: true,
    }

    validateForm(event: React.FormEvent) {
        const { isValidAbbreviation } = this.state
        event.preventDefault()

        if (isValidAbbreviation) {
            this.submitForm()
            return
        }
        return
    }

    updateTenantName(tenantName: string) {
        this.setState({ tenantName: tenantName, isValidTenantName: tenantName.length > 0 })
    }

    submitForm() {
        const { tenantName, tenantAbbreviation } = this.state
        this.setState({ submittingForm: true })
        const body = JSON.stringify({ module: 'TenantAdministration', action: 'createTenant', request: { id: undefined, name: tenantName, abbreviation: tenantAbbreviation} })

        BusHttpRequest.fetch<ITenant>({ method: 'POST', body })
            .then((res) => {
                if (this.props.onAddTenant) this.props.onAddTenant(res);
                this.setState({ openModal: false, submittingForm: false, errorMessage: undefined }, () => this.resetForm())
            })
            .catch(error => this.setState({ errorMessage: 'Oh no. An error has occurred.', submittingForm: false }))
    }

    resetForm() {
        this.setState({ tenantName: '', tenantAbbreviation: '', isValidAbbreviation: true})
    }

    updateTenantAbbreviation(temporaryTenantAbbreviation: string) {
        let isValidAbbreviation = true;
        this.props.tenants.forEach((tenant) => {
            if (tenant.abbreviation === temporaryTenantAbbreviation.toUpperCase()) isValidAbbreviation = false;
        });
        this.setState({ tenantAbbreviation: temporaryTenantAbbreviation.toUpperCase(), isValidAbbreviation: isValidAbbreviation })
    }

    updateTenant(tenantId: number) {
        this.setState({ tenantId })
    }

    renderValidationText(text: string, isValid: boolean) {
        return (
            <>
                <span style={{ color: isValid ? 'green' : 'red' }}>&#42;{text}</span><br />
            </>
        )
    }

    render() {
        const { openModal, tenantName, submittingForm, isValidAbbreviation, isValidTenantName, tenantAbbreviation, errorMessage } = this.state
        return (
            <div>
                <Button variant="primary" onClick={() => this.setState({ openModal: true })}>Add Tenant</Button>
                <div id="addUserModal" className="modal" tabIndex={-1} role="dialog">
                    <Form onSubmit={event => this.validateForm(event)}>
                        <Modal show={openModal}>
                            <Modal.Header closeButton>
                                Add Tenant
                            </Modal.Header>
                            <Modal.Body>
                                {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                                {!isValidTenantName && <Alert variant="danger">Please enter a valid name.</Alert>}
                                {!isValidAbbreviation && <Alert variant="danger">Please enter an unique abbreviation.</Alert>}
                                <Form.Group>
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control required type="text" value={tenantName} onChange={event => this.updateTenantName(event.currentTarget.value)} />
                                </Form.Group>
                                <Form.Group controlId="formBasicPassword">
                                    <Form.Label>Abbreviation</Form.Label>
                                    <Form.Control type="text" value={tenantAbbreviation} onChange={event => this.updateTenantAbbreviation(event.currentTarget.value)}/>
                                    <Form.Text className="text-muted">
                                        {this.renderValidationText("Must be unique", isValidAbbreviation)}
                                    </Form.Text>
                                </Form.Group>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" disabled={submittingForm} onClick={() => this.setState({ openModal: false }, () => this.resetForm())}>Close</Button>
                                <Button variant="danger" disabled={submittingForm} onClick={(event) => this.validateForm(event)}>Save {submittingForm && <i className="fas fa-cog fa-spin" />}</Button>
                            </Modal.Footer>
                        </Modal>
                    </Form>
                </div>
            </div>
        )
    }
}