import * as React from 'react'
import { AddUserModal } from './AddUserModal'
import { BusHttpRequest, IUser, ITenant } from '../../components/utility'
import { Alert } from 'react-bootstrap'

interface IAdminPageState {
    token: string
    users: IUser[]
    tenants: ITenant[]
    loadingUsers: boolean
    errorMessage?: string
}


class AdminPage extends React.Component<{}, IAdminPageState> {
    state: IAdminPageState = {
        token: '',
        users: [],
        tenants: [],
        loadingUsers: false,
        errorMessage: undefined,
    }

    componentDidMount() {
        this.loadUsers()
        this.loadTenants()
    }

    /***
     * After we add a user refresh the user table
     */
    onAddUser() {
        this.setState({ users: [] }, () => this.loadUsers())
    }

    loadUsers() {
        this.setState({ loadingUsers: true })
        const body = JSON.stringify({ module: 'UserAdministration', action: 'listUsers', request: { username: '', temporaryPassword: '', email: '' } })

        BusHttpRequest.fetch<IUser[]>({ method: 'POST', body })
            .then((response: IUser[]) => this.setState({ users: response, errorMessage: undefined }))
            .catch(error => this.setState({ errorMessage: 'Oh no. An error has occurred.' }))
            .finally(() => this.setState({ loadingUsers: false }))
    }

    loadTenants() {
        this.setState({ loadingUsers: true })
        const body = JSON.stringify({ module: 'TenantAdministration', action: 'listTenants', request: { id: 0, name: '', abbreviation: '' } })

        BusHttpRequest.fetch<ITenant[]>({ method: 'POST', body })
            .then((response: ITenant[]) => this.setState({ tenants: response, errorMessage: undefined }))
            .catch(error => this.setState({ errorMessage: 'Oh no. An error has occurred.' }))
    }

    confirmDelete(event: React.FormEvent, user: IUser) {
        event.preventDefault()

        const confirmed = window.confirm('Are you sure you want to delete ' + user.username + '?')

        if (confirmed) {
            this.deleteUser(user)
        }
    }

    deleteUser(user: IUser) {
        this.setState({ loadingUsers: true })
        const body = JSON.stringify({ module: 'UserAdministration', action: 'deleteUser', request: { username: user.username, temporaryPassword: '', email: '', userId: user.id } })

        BusHttpRequest.fetch<IUser>({ method: 'POST', body })
            .then(() => this.loadUsers())
            .catch(error => this.setState({ errorMessage: 'Oh no. An error has occurred.' }))
    }

    render() {
        const { users, loadingUsers, token, tenants, errorMessage } = this.state
        return (
            <div className="container flex mt-5">
                <h3>User Administration {loadingUsers && <i className="fas fa-cog fa-spin" />}</h3>
                <div className="m-2 text-right">
                    <AddUserModal token={token} tenants={tenants} onAddUser={() => this.onAddUser()} />
                </div>
                <div className="m-2 text-center">
                    {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                </div>

                {users ?
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>Username</th>
                                <th>Email</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map(user => {
                                return (
                                    <tr key={user.username}>
                                        <td>{user.username}</td>
                                        <td>{user.email}</td>
                                        <td></td>
                                        <td><button type="button" className="btn btn-danger" onClick={(event) => this.confirmDelete(event, user)}>Delete</button></td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    : null}
            </div>
        )
    }
}

export {
    AdminPage
}