import * as React from 'react'
import {ITenant, IUser, BusHttpRequest} from "../../components/utility";
import {Alert, OverlayTrigger, Tooltip} from "react-bootstrap";
import {AddTenantModal} from "./AddTenantModal";
import {EditTenantModal} from "./EditTenantModal";

interface ITenantsPageState {
    errorMessage?: string
    loadingTenants: boolean
    loadingUsers: boolean
    tenants: ITenant[]
    token: string
    users: IUser[]
    usersPerTenant: object
}

export class TenantsPage extends React.Component<{}, ITenantsPageState> {
    state: ITenantsPageState = {
        errorMessage: undefined,
        loadingTenants: false,
        loadingUsers: false,
        tenants: [],
        token: '',
        users: [],
        usersPerTenant: {}
    }

    componentDidMount() {
        this.loadTenants()
    }

    loadTenants() {
        this.setState({ loadingTenants: true })
        const body = JSON.stringify({ module: 'TenantAdministration', action: 'listTenants', request: { id: 0, name: '', abbreviation: '' } })

        BusHttpRequest.fetch<ITenant[]>({ method: 'POST', body })
            .then((response: ITenant[]) => this.setState({ tenants: response, errorMessage: undefined }, () => this.loadUsers()))
            .catch(error => this.setState({ errorMessage: error }))
            .finally(() => this.setState({loadingTenants: false}))
    }

    loadUsers() {
        this.setState({ loadingUsers: true })
        const body = JSON.stringify({ module: 'UserAdministration', action: 'listUsers', request: { username: '', temporaryPassword: '', email: '' } })

        BusHttpRequest.fetch<IUser[]>({ method: 'POST', body })
            .then((response: IUser[]) => {
                let countedUsers = response.reduce((acc:any, curr) => {
                    if (curr.tenant.id in acc) {
                        acc[curr.tenant.id] ++
                    }
                    else {
                        acc[curr.tenant.id] = 1
                    }
                    return acc
                }, {})
                this.setState({users: response, errorMessage: undefined, usersPerTenant: countedUsers})
            })
            .catch(error => this.setState({ errorMessage: error }))
            .finally(() => this.setState({ loadingUsers: false }))
    }


    onAddTenant(newTenant: ITenant): void {
        let tenants = Object.assign(this.state.tenants);
        tenants.push(newTenant);
        this.setState({tenants: tenants});
    }

    confirmDelete(event: React.FormEvent, tenant: ITenant) {
        event.preventDefault()

        const confirmed = window.confirm('Are you sure you want to delete ' + tenant.name + '?')

        if (confirmed) {
            this.deleteTenant(tenant)
        }
    }

    deleteTenant(tenant: ITenant) {
        this.setState({ loadingUsers: true })
        const body = JSON.stringify({ module: 'TenantAdministration', action: 'deleteTenant', request: { id: tenant.id, name: tenant.name, abbreviation: tenant.abbreviation } })

        BusHttpRequest.fetch<IUser>({ method: 'POST', body })
            .then(() => this.loadTenants())
            .catch(error => this.setState({ errorMessage: error }))
    }

    onEditTenant(): void {
        this.loadTenants();
    }

    render() {
        const { loadingTenants, errorMessage, tenants, token, users, usersPerTenant } = this.state;
        return (
            <div className="container flex mt-5">
                <h3>Tenant Management {loadingTenants && <i className="fas fa-cog fa-spin" />}</h3>
                <div className="m-2 text-right">
                    <AddTenantModal token={token} tenants={tenants} onAddTenant={(newTenant) => this.onAddTenant(newTenant)} />
                </div>
                <div className="m-2 text-center">
                    {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                </div>

                {tenants && users ?
                    <Table records={tenants}
                           usersPerTenant={usersPerTenant}
                           token={token}
                           confirmDelete={(e, t) => this.confirmDelete(e, t)}
                           onEditTenant={() => this.onEditTenant()}
                    />
                    : null}
            </div>
        )
    }

}

interface ITableProps {
    records: any[]
    usersPerTenant: object
    confirmDelete: (event: React.FormEvent, tenant: ITenant) => void
    token: string
    onEditTenant: () => void
}
interface ITableState {
    records: ITenant[]
    order: string
    orderBy: keyof ITenant
}
class Table extends React.Component<ITableProps, ITableState> {
    state: ITableState  ={
        records: [],
        order: 'desc',
        orderBy: "name"
    }

    componentDidUpdate(prevProps: Readonly<ITableProps>, prevState: Readonly<ITableState>, snapshot?: any) {
        if (this.props.records !== prevProps.records || this.state.order !== prevState.order || this.state.orderBy !== prevState.orderBy) {
            this.sortRecords();
        }
    }

    sortRecords() {
        const { order, orderBy } = this.state;
        let records: ITenant[] = Object.assign(this.props.records);

        if (orderBy !== "id") {
            if (order === 'desc') {
                records.sort((a, b) => {
                    const valueA = a[orderBy].toLowerCase();
                    const valueB = b[orderBy].toLowerCase();
                    if (valueA < valueB) return -1;
                    if (valueA > valueB) return 1;
                    return 0;
                });
            } else {
                records.sort((a, b) => {
                    const valueA = a[orderBy].toLowerCase();
                    const valueB = b[orderBy].toLowerCase();
                    if (valueA > valueB) return -1;
                    if (valueA < valueB) return 1;
                    return 0;
                });
            }

            this.setState({records: records});
        }
    }

    toggleSort(property: keyof ITenant) {
        const {order} = this.state;
        let newOrder = (order == 'desc' ? 'asc' : 'desc');
        this.setState({order: newOrder, orderBy: property});
    }


    render() {
        const { records, orderBy, order } = this.state;
        const { usersPerTenant, token } = this.props;
        return (
            <table className="table table-striped">
                <thead>
                <tr>
                    <th onClick={() => this.toggleSort('name')} style={{cursor: "pointer"}}>Tenant <i className={(orderBy == "name" ? (order == "desc" ? "fas fa-arrow-down" : "fas fa-arrow-up") : "invisible")}/></th>
                    <th onClick={() => this.toggleSort('abbreviation')} style={{cursor: "pointer"}}>Tenant Abbreviation <i className={(orderBy == "abbreviation" ? (order == "desc" ? "fas fa-arrow-down" : "fas fa-arrow-up") : "invisible")}/></th>
                    <th/>
                </tr>
                </thead>
                <tbody>
                {records.map(record => {
                    // todo: fix TS7053
                    // @ts-ignore
                    const disableButton = (usersPerTenant.hasOwnProperty(record.id) && usersPerTenant[record.id] > 0)
                    return (
                        <tr key={record.name}>
                            <td>{record.name}</td>
                            <td>{record.abbreviation}</td>
                            <td className="d-flex justify-content-around">
                                {disableButton &&
                                <OverlayTrigger
                                    key={'top'}
                                    placement={'top'}
                                    overlay={
                                        <Tooltip id={`tooltip-top`}>
                                            Cannot delete this tenant until all users are removed from the tenant.
                                        </Tooltip>
                                    }
                                >
                                    <button type="button" className="btn btn-danger disabled">Delete</button>
                                </OverlayTrigger>
                                }
                                {!disableButton &&
                                <button type="button" className="btn btn-danger" disabled={disableButton} onClick={(event) => this.props.confirmDelete(event, record)}>Delete</button>
                                }
                                <EditTenantModal token={token} tenant={record} onEditTenant={() => this.props.onEditTenant()}/>
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        );
    }
}