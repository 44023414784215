import * as React from "react";
import { Modal, Button, Form, Alert } from "react-bootstrap";
import { ITenant, BusHttpRequest, IUser } from "../../../components/utility";

interface IAddUserModalProps {
  token: string;
  tenants: ITenant[];
  onAddUser?: () => void;
}

interface IAddUserModalState {
  temporaryPassword: string;
  email: string;
  tenantId: number;
  system: string;
  openModal: boolean;
  hasRequiredNumbers: boolean;
  hasRequiredSpecialCharacters: boolean;
  hasRequiredUppercaseLetter: boolean;
  hasRequiredLowercaseLetter: boolean;
  isAtLeastEightCharacters: boolean;
  submittingForm: boolean;
  isValidEmail: boolean;
}

export class AddUserModal extends React.Component<
  IAddUserModalProps,
  IAddUserModalState
> {
  state: IAddUserModalState = {
    temporaryPassword: "",
    email: "",
    tenantId: 0,
    system: "",
    openModal: false,
    hasRequiredNumbers: false,
    hasRequiredSpecialCharacters: false,
    hasRequiredUppercaseLetter: false,
    hasRequiredLowercaseLetter: false,
    isAtLeastEightCharacters: false,
    submittingForm: false,
    isValidEmail: false,
  };

  validateForm(event: React.FormEvent) {
    const {
      hasRequiredLowercaseLetter,
      hasRequiredNumbers,
      hasRequiredSpecialCharacters,
      hasRequiredUppercaseLetter,
      isAtLeastEightCharacters,
      isValidEmail,
    } = this.state;
    event.preventDefault();

    if (
      hasRequiredLowercaseLetter &&
      hasRequiredNumbers &&
      hasRequiredSpecialCharacters &&
      hasRequiredUppercaseLetter &&
      isAtLeastEightCharacters &&
      isValidEmail
    ) {
      this.submitForm();
      return;
    }

    return;
  }

  updateEmail(email: string) {
    const isValidEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
      email
    );
    this.setState({ email, isValidEmail });
  }

  submitForm() {
    const { temporaryPassword, email, tenantId, system } = this.state;
    this.setState({ submittingForm: true });
    const body = JSON.stringify({
      module: "UserAdministration",
      action: "adduser",
      request: {
        username: email,
        temporaryPassword: temporaryPassword,
        email,
        tenantId,
        system,
      },
    });

    BusHttpRequest.fetch<IUser>({ method: "POST", body })
      .then(() => {
        if (this.props.onAddUser) this.props.onAddUser();
        this.setState({ openModal: false, submittingForm: false }, () =>
          this.resetForm()
        );
      })
      .catch((error) => console.log(error));
  }

  resetForm() {
    this.setState({
      email: "",
      temporaryPassword: "",
      tenantId: 0,
      system: "",
      hasRequiredLowercaseLetter: false,
      hasRequiredNumbers: false,
      hasRequiredSpecialCharacters: false,
      hasRequiredUppercaseLetter: false,
      isAtLeastEightCharacters: false,
    });
  }

  updatePassword(temporaryPassword: string) {
    const hasRequiredNumbers = /\d+/.test(temporaryPassword);
    const hasRequiredSpecialCharacters = /[~`!#$%^&*+=\-[\]\\';,/{}|\\":<>?]/.test(
      temporaryPassword
    );
    const hasRequiredUppercaseLetter = /[A-Z]/.test(temporaryPassword);
    const hasRequiredLowercaseLetter = /[a-z]/.test(temporaryPassword);
    const isAtLeastEightCharacters = temporaryPassword.length >= 8;
    this.setState({
      hasRequiredLowercaseLetter,
      hasRequiredNumbers,
      hasRequiredSpecialCharacters,
      hasRequiredUppercaseLetter,
      temporaryPassword,
      isAtLeastEightCharacters,
    });
  }

  updateTenant(tenantId: number) {
    const tenant = this.props.tenants.find((value) => value.id === tenantId);
    const system = tenant ? tenant.name : "";
    this.setState({ tenantId, system });
  }

  renderValidationText(text: string, isValid: boolean) {
    return (
      <>
        <span style={{ color: isValid ? "green" : "red" }}>&#42;{text}</span>
        <br />
      </>
    );
  }

  render() {
    const {
      openModal,
      email,
      temporaryPassword,
      hasRequiredLowercaseLetter,
      hasRequiredNumbers,
      hasRequiredSpecialCharacters,
      hasRequiredUppercaseLetter,
      isAtLeastEightCharacters,
      submittingForm,
      isValidEmail,
      tenantId,
    } = this.state;
    const { tenants } = this.props;
    const passwordValid =
      hasRequiredLowercaseLetter &&
      hasRequiredNumbers &&
      hasRequiredSpecialCharacters &&
      hasRequiredUppercaseLetter &&
      isAtLeastEightCharacters;
    return (
      <div>
        <Button
          variant="primary"
          onClick={() => this.setState({ openModal: true })}
        >
          Add User
        </Button>
        <div id="addUserModal" className="modal" tabIndex={-1} role="dialog">
          <Form onSubmit={(event) => this.validateForm(event)}>
            <Modal show={openModal}>
              <Modal.Header closeButton>Add User</Modal.Header>
              <Modal.Body>
                {!isValidEmail && (
                  <Alert variant="danger">Please enter a valid email.</Alert>
                )}
                {!passwordValid && (
                  <Alert variant="danger">
                    Please review password requirements.
                  </Alert>
                )}
                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    required
                    type="email"
                    value={email}
                    onChange={(event) =>
                      this.updateEmail(event.currentTarget.value)
                    }
                  />
                  <Form.Text className="text-muted"></Form.Text>
                </Form.Group>
                <Form.Group controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    value={temporaryPassword}
                    onChange={(event) =>
                      this.updatePassword(event.currentTarget.value)
                    }
                  />
                  <Form.Text className="text-muted">
                    {this.renderValidationText(
                      "Require Numbers",
                      hasRequiredNumbers
                    )}
                    {this.renderValidationText(
                      "Require special characters",
                      hasRequiredSpecialCharacters
                    )}
                    {this.renderValidationText(
                      "Require uppercase letters",
                      hasRequiredUppercaseLetter
                    )}
                    {this.renderValidationText(
                      "Require lowercase letters",
                      hasRequiredLowercaseLetter
                    )}
                    {this.renderValidationText(
                      "At least 8 Characters",
                      isAtLeastEightCharacters
                    )}
                  </Form.Text>
                </Form.Group>

              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  disabled={submittingForm}
                  onClick={() =>
                    this.setState({ openModal: false }, () => this.resetForm())
                  }
                >
                  Close
                </Button>
                <Button
                  variant="danger"
                  disabled={submittingForm}
                  onClick={(event) => this.validateForm(event)}
                >
                  Save {submittingForm && <i className="fas fa-cog fa-spin" />}
                </Button>
              </Modal.Footer>
            </Modal>
          </Form>
        </div>
      </div>
    );
  }
}
