
interface IUser {
    id: number
    email: string
    username: string
    tenant: ITenant
}

interface ITenant {
    id: number
    name: string
    abbreviation: string
}

export interface IRequestInitWithParams extends RequestInit {
    params?: { [key: string]: any }
    formData?: any
}

const defaultRequestInit: IRequestInitWithParams = {
    method: 'GET',
    headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': ""
    })
}

const BLRequestInit: IRequestInitWithParams = {
    method: 'POST',
    headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': ""
    })
}

class BusHttpRequest {

    /**
    * Update token in authorization header
    * @param token
    */
    public static updateAuthToken(token: string) {
        defaultRequestInit.headers = new Headers({
            'Content-Type': 'application/json',
            'Authorization': token
        })
    }

    public static updateBLAuth(token: string) {
        BLRequestInit.headers = new Headers({
            'Content-Type': 'application/json',
            'Authorization': token
        })
    }

    public static fetchApp<U>(init?: IRequestInitWithParams) {
        init = { ...{}, ...BLRequestInit, ...init }
        return new Promise<U>((resolve, reject) => {
            fetch('https://1x6uadximd.execute-api.us-east-1.amazonaws.com/dev/services', init)
                .then((res) => {
                    if (res.ok) {
                        return res.text()
                    } else {
                        throw res
                    }
                })
                .then((text) => text.replace(/\\\\/g, '\\').replace(/\\"/g, '"'))
                .then((text) => {
                    return JSON.parse(text)
                }
                )
                .then((json: U) => {
                    resolve(json)
                })
                .catch(error => reject(error))
        })
    }

    public static fetchEmbed<U>(init?: IRequestInitWithParams) {
        init = { ...{}, ...defaultRequestInit, ...init }
        return new Promise<U>((resolve, reject) => {
            fetch('https://q2x23je2ef.execute-api.us-east-1.amazonaws.com/default/GetUrlEmbedBusSolutions', init)
            
                .then((res) => {
                    if (res.ok) {
                        return res.text()
                    } else {
                        throw res
                    }
                })
                .then((text) => text.replace(/\\/g, '').replace(/"{"/g, '{"').replace(/"}"/g, '"}'))
                .then((text) => {
                    return JSON.parse(text)
                }
                )
                .then((json: U) => {
                    resolve(json)
                })
                .catch(error => reject(error))
        })
    }
    
    public static fetch<U>(init?: IRequestInitWithParams) {
        init = { ...{}, ...defaultRequestInit, ...init }
        return new Promise<U>((resolve, reject) => {
            fetch('https://jvdzkapwqa.execute-api.us-east-1.amazonaws.com/default/busslns_serverless', init)
                .then((res) => {
                    if (res.ok) {
                        return res.text()
                    } else {
                        throw res
                    }
                })
                .then((text) => text.replace(/\\/g, '').replace(/"{"/g, '{"').replace(/"}"/g, '"}'))
                .then((text) => {
                    return JSON.parse(text)
                }
                )
                .then((json: U) => {
                    resolve(json)
                })
                .catch(error => reject(error))
        })
    }
}

export { BusHttpRequest }
export type {
    IUser,
    ITenant
}
