import * as React from 'react'
import { Modal, Button, Form, Alert } from 'react-bootstrap'
import { ITenant, BusHttpRequest } from '../../../components/utility'

interface IEditTenantModalProps {
    token: string
    onEditTenant?: (newTenant: ITenant) => void
    tenant: ITenant
}

interface IEditTenantModalState {
    errorMessage?: string
    tenantName: string
    openModal: boolean
    submittingForm: boolean
    isValidTenantName: boolean
}

export class EditTenantModal extends React.Component<IEditTenantModalProps, IEditTenantModalState> {
    state: IEditTenantModalState = {
        tenantName: '',
        openModal: false,
        submittingForm: false,
        isValidTenantName: true,
    }

    constructor(props: IEditTenantModalProps) {
        super(props);
        this.state.tenantName = props.tenant.name;
    }


    componentDidUpdate(prevProps: Readonly<IEditTenantModalProps>, prevState: Readonly<IEditTenantModalState>, snapshot?: any) {
        if (this.props.tenant !== prevProps.tenant) this.resetForm();
    }

    validateForm(event: React.FormEvent) {
        const { isValidTenantName } = this.state
        event.preventDefault()

        if (isValidTenantName) {
            this.submitForm()
            return
        }
        return
    }

    updateTenantName(tenantName: string) {
        this.setState({ tenantName: tenantName, isValidTenantName: tenantName.length > 0 })
    }

    submitForm() {
        const { tenantName } = this.state
        this.setState({ submittingForm: true })
        const body = JSON.stringify({ module: 'TenantAdministration', action: 'editTenant', request: { id: this.props.tenant.id, name: tenantName, abbreviation: this.props.tenant.abbreviation} })

        BusHttpRequest.fetch<ITenant>({ method: 'POST', body })
            .then((res) => {
                if (this.props.onEditTenant) this.props.onEditTenant(res);
                this.setState({ openModal: false, submittingForm: false, errorMessage: undefined }, () => this.resetForm())
            })
            .catch(error => this.setState({ errorMessage: error, submittingForm: false }))
    }

    resetForm() {
        this.setState({ tenantName: this.props.tenant.name})
    }

    renderValidationText(text: string, isValid: boolean) {
        return (
            <>
                <span style={{ color: isValid ? 'green' : 'red' }}>&#42;{text}</span><br />
            </>
        )
    }

    render() {
        const { openModal, tenantName, submittingForm, isValidTenantName, errorMessage } = this.state
        return (
            <div>
                <Button variant="primary" onClick={() => this.setState({ openModal: true })}>Edit</Button>
                <div id="addUserModal" className="modal" tabIndex={-1} role="dialog">
                    <Form onSubmit={event => this.validateForm(event)}>
                        <Modal show={openModal}>
                            <Modal.Header closeButton>
                                Edit Tenant
                            </Modal.Header>
                            <Modal.Body>
                                {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                                {!isValidTenantName && <Alert variant="danger">Please enter a valid name.</Alert>}
                                <Form.Group>
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control required type="text" value={tenantName} onChange={event => this.updateTenantName(event.currentTarget.value)} />
                                </Form.Group>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" disabled={submittingForm} onClick={() => this.setState({ openModal: false }, () => this.resetForm())}>Close</Button>
                                <Button variant="danger" disabled={submittingForm} onClick={(event) => this.validateForm(event)}>Save {submittingForm && <i className="fas fa-cog fa-spin" />}</Button>
                            </Modal.Footer>
                        </Modal>
                    </Form>
                </div>
            </div>
        )
    }
}