import * as React from "react";
import { Auth, Hub } from "aws-amplify";
import "./App.css";
import {
  AmplifyAuthenticator,
  AmplifySignUp,
  AmplifySignOut,
  AmplifySignIn,
} from "@aws-amplify/ui-react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { HomePage } from "./containers/HomePage";
import { Navbar } from "./components/Navbar";
import { AdminRoute } from "./components/Navbar/AdminRoute";
import { BusHttpRequest } from "./components/utility";
import { TenantsRoute } from "./components/Navbar/TenantsRoute";
import { DashboardPage} from "./containers/Dashboard";


class App extends React.Component<{}, {}> {
  state = {
    user: null,
    isAdmin: false,
    system: "",
  };

  componentDidMount() {
    this.getUserData();
    Hub.listen("auth", this, "onHubCapsule");
  }

  getUserData = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const userInfo = await Auth.currentUserInfo();
    if (user) {
      BusHttpRequest.updateAuthToken(user.signInUserSession.idToken.jwtToken);
      this.setState({
        user,
        isAdmin: user.signInUserSession.accessToken.payload[
          "cognito:groups"
        ].includes("Administrator"),
        system: userInfo.attributes["custom:tenant"]
      });
    } else {
      this.setState({ user: null, isAdmin: false, system: "" });
    }
  };

  onHubCapsule = (capsule: any) => {
    switch (capsule.payload.event) {
      case "signIn":
        this.getUserData();
        break;
      case "signUp":
        break;
      case "signOut":
        this.setState({ user: null, isAdmin: false, system: "" });
        break;
      default:
        return;
    }
  };


  render() {
    const { user, isAdmin, system } = this.state;
    return (
      <div>
        {!user && (
          <div className="App">
            <div className="m-2">
              <img src="/NCI-Logo.png" alt="logo" height="300px" />
            </div>
            <AmplifyAuthenticator usernameAlias="email">
              <AmplifySignUp hidden={true} />
              <AmplifySignIn slot="sign-in" hideSignUp></AmplifySignIn>
              <AmplifySignOut buttonText="Logout" />
            </AmplifyAuthenticator>
          </div>
        )}
        {user && (
          <Router>
            <>
              {/* Navigation */}
              <Navbar isAdmin={isAdmin} user={user} system={system} />

              {/* Routes */}
              <div className="container">
                <Route exact path="/" component={HomePage} />
                <Route exact path="/Dashboard" component={DashboardPage}/> 
                <AdminRoute isAdmin={isAdmin} />
                <TenantsRoute isAdmin={isAdmin} />
              </div>
            </>
          </Router>
        )}
      </div>
    );
  }
}

export default App;
